


























import {
  Component, Vue, Watch, Prop,
} from "vue-property-decorator";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import EntityField from "./EntityField.vue";

@Component({ components: { flatPickr, EntityField } })
export default class EntityForm extends Vue {
  @Prop() model: any;

  @Prop() columns: string[];

  @Prop({ default: () => [] }) joinKeys: string[];

  @Prop({ default: () => {} }) defaultEntity: any;

  @Prop({ default: true }) enableDelete: boolean;

  @Prop({ default: false }) readOnly: boolean;

  @Prop({ default: () => [] }) actions: {
    name: string;
    icon: string;
    color: string;
    action: any;
  }[];

  entityDescription: any = [];

  entity: any = {};

  id = "";

  flatPickrConfig = {
    enableTime: true,
    dateFormat: "Z",
    altInput: true,
    altFormat: "d/m/Y H:i",
  };

  async mounted() {
    this.$vs.loading();
    try {
      this.entity = { ...this.defaultEntity };
      this.entityDescription = await this.model.getDescription();
      this.id = this.$router.currentRoute.params.id;
      if (this.id !== "novo") {
        this.entity = await this.model.read(this.id);
      }
    } catch (error) {
      console.error(error);
    }
    this.$vs.loading.close();
  }

  get fields() {
    if (!this.entityDescription) {
      return [];
    }
    return this.columns.map((fieldKey: string) => this.entityDescription.find((field: any) => field.key === fieldKey) || {});
  }

  get createOrUpdateButtonText() {
    return this.id === "novo" ? "Cadastrar" : "Salvar";
  }

  async createOrUpdate() {
    this.$vs.loading();
    try {
      if (this.id === "novo") {
        const entity = await this.model.create(this.entity);
        this.id = entity.id;
        this.$router.replace(this.$router.currentRoute.path.replace("novo", entity.id));
      } else {
        await this.model.update(this.id, this.entity);
      }
      this.$vs.notification({
        title: "Sucesso", text: "Os dados foram enviados.", iconPack: "feather", icon: "icon-check", color: "success",
      });
    } catch (error) {
      console.error(error);
      this.$vs.notification({
        title: "Erro", text: error.friendlyMessage || "Ocorreu um erro. Tente novamente.", iconPack: "feather", icon: "icon-alert-circle", color: "danger",
      });
    }
    this.$vs.loading.close();
  }

  async remove() {
    if (!(await new Promise((resolve) => {
      this.$vs.dialog({
        color: "danger",
        title: `Confirma a exclusão?`,
        text: "Não será possível reverter essa operação.",
        type: "confirm",
        acceptText: "Excluir",
        cancelText: "Cancelar",
        accept: () => resolve(true),
        cancel: () => resolve(false),
      });
    }))) {
      return;
    }

    this.$vs.loading();
    try {
      await this.model.delete(this.entity.id);
      this.$vs.notification({
        title: "Sucesso", text: "O registro foi excluído.", iconPack: "feather", icon: "icon-check", color: "success",
      });
      this.$router.back();
    } catch (error) {
      console.error(error);
      this.$vs.notification({
        title: "Erro", text: "Ocorreu um erro. Tente novamente.", iconPack: "feather", icon: "icon-alert-circle", color: "danger",
      });
    }
    this.$vs.loading.close();
  }
}
