import { CrudModel } from './_crud';
// import appointmentModel from './appointment.model';
// import userModel from './user.model';

export class AppointmentReportModel extends CrudModel {
	constructor() {
		super('app/appointmentReport', [], [
    //   { fieldKey: 'psychologistUser', model: userModel },
    //   { fieldKey: 'patientUser', model: userModel },
    //   { fieldKey: 'appointment', model: appointmentModel },
    ]);
	}
}

export default new AppointmentReportModel();