




import { Component, Vue } from "vue-property-decorator";  
import EntityCrud from "../../../components/EntityCrud.vue";
import { FilterDefinition } from "../../../components/EntityFilter.vue";

//import appointmentEventModel from "@/models/appointment-event.model";
import appointmentReportModel from "@/models/appointment-report.model";
import companyModel from "@/models/company.model";

@Component({
  components: { EntityCrud },
})
export default class Appointments extends Vue {
  model: any = appointmentReportModel;

  title = "Consultas";
  enableExport = true;
  filterDefinition: FilterDefinition = [
    /*
    { fieldKey: "startDateTime", label: "Data da consulta" },
    { fieldKey: "status", defaultSelectOptions: ["CONFIRMATION_PENDING", "CONFIRMED", "COMPLETED", "FAILED"] },
    { fieldKey: "voucher.company", model: companyModel },
    { fieldKey: "appointmentType", defaultSelectOptions: ["FIQUEOK", "TEAMS", "WHATSAPP"] },      
     */
    { fieldKey: "startDateTime", label: "Data da consulta" },
    //{ fieldKey: "appointmentStatus", defaultSelectOptions: ["CONFIRMATION_PENDING", "CONFIRMED", "COMPLETED", "FAILED"] },
    { fieldKey: "status", defaultSelectOptions: ["REALIZADO", "CANCELADO"] },      
    { fieldKey: "voucher.company", model: companyModel },
    { fieldKey: "appointmentType", defaultSelectOptions: ["FIQUEOK", "TEAMS", "WHATSAPP"] },        
  ];
  
  //tableColumns = ["startDateTime", "kind", "appointmentType", "patientUser", "psychologistUser", "status", "voucher.code", "voucher.company.name"];
  //exportColumns = ["patientUser.register", "patientUser.userType", "startDateTime", "kind", "appointmentType", "patientUser", "psychologistUser", "status", "voucher.code", "voucher.company.name", "statusStatus", "statusReason", "statusPriority", "medicalRecord"];
  //tableColumns = ["patientUser.register", "patientUser.userType", "startDateTime", "kind", "appointmentType", "patientUser", "psychologistUser", "status", "voucher.code", "voucher.company.name", "statusStatus", "statusReason", "statusPriority", "medicalRecord"];

  /*
  tableColumns = ["appointment.startDateTime", "appointmentKind", "appointment.appointmentType", "patientUser", "psychologistUser", "appointment.status", "voucher.code", "voucher.company.name"];
  //headers que serão exibidos na grid de resultados
  tableHeaders = ["Data/Hora", "Duração", "Tipo", "Paciente", "Psicólogo", "Status", "Voucher", "Empresa"];
  exportColumns = ["patientUser.register", "patientUser.userType", "appointmentStartDateTime", "appointmentKind", "appointment.appointmentType", "patientUser", "psychologistUser", "appointment.status", "voucher.code", "voucher.company.name", "appointment.statusStatus", "appointment.statusReason", "appointment.statusPriority", "appointment.medicalRecord"];
  //headers que serão montados na planilha
  exportHeaders = ["Matrícula", "Tipo funcionário", "Data/Hora", "Duração", "Tipo", "Paciente", "Psicólogo", "Status", "Voucher", "Empresa", "Status da Consulta", "Motivo", "Prioridade", "Prontuário do paciente"];
  */

  tableColumns = ["startDateTime", "duration", "appointmentType", "patientUserName", "psychologistUserName", "status", "voucherCode", "voucherCompanyName"];
  //headers que serão exibidos na grid de resultados
  tableHeaders = ["Data/Hora", "Duração", "Canal", "Paciente", "Psicólogo", "Status", "Voucher", "Empresa"];
  exportColumns = ["patientUserRegister", "patientUserType", "startDateTime", "appointmentKind", "appointmentType", "patientUserName", "psychologistUserName", "status", "voucherCode", "voucherCompanyName", "statusStatus", "statusReason", "statusPriority", "medicalRecord"];
  //headers que serão montados na planilha
  exportHeaders = ["Matrícula", "Tipo funcionário", "Data/Hora", "Duração", "Tipo", "Paciente", "Psicólogo", "Status", "Voucher", "Empresa", "Status da Consulta", "Motivo", "Prioridade", "Prontuário do paciente"];



  filter = {
    'appointmentkind': {
      $in: ["SINGLE", "DOUBLE"],
    },
  };

  formColumns = ["startDateTime", "kind", "patientUser", "psychologistUser"];
  defaultEntity = {};

  mounted() {
    this.filterDefinition.push({ fieldKey: "psychologistUserId", value: this.userLogged, kind: "hidden" });
  }

  get userLogged() {
     return this.$store.state.activeUser?.id;
  }
}
