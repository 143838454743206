



























































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { endOfMonth, startOfMonth } from "date-fns";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import AusterButton from "@/components/AusterButton.vue";

export class FilterDefinitionField {
  fieldKey: string;
  label?: string;
  kind?: "text" | "date_interval" | "select_multiple" | "select_single_relation" | "hidden";
  defaultSelectOptions?: string[];
  model?: any;
  value? : any;
  options?: string[];
}

export type FilterDefinition = FilterDefinitionField[];

//foi implementado para deixar igual ao admin, perimitir filtrar para relatórios

@Component({ components: { flatPickr, AusterButton } })
export default class EntityFilter extends Vue {
  @Prop() model: any;
  @Prop() filterDefinition: FilterDefinition;
  @Prop({ default: () => ({}) }) filterEntity: any;

  entityDescription: any = [];

  selectFieldsOptions: { [key: string]: { id: string, label: string }[] } = {};
  filterFields: any[] = [];

  flatPickrConfig = {
    enableTime: false,
    dateFormat: "Z",
    altInput: true,
    altFormat: "d/m/Y",
  };

  async mounted() {
    const loading = this.$vs.loading();
    //console.log(startOfMonth(new Date()));
    try {
      this.entityDescription = await this.model.getDescription();
      const filterFields = await this.getFilterFields();

      // initialize filterEntity with default values
      filterFields.forEach(filterField => {
        switch (filterField.kind) {
          case "date_interval":
            this.$set(this.filterEntity, filterField.key, {
              $between: [this.getDateString(startOfMonth(new Date())), this.getDateString(endOfMonth(new Date()))],
              //$between: ['2024-04-01', new Date()],
            });
            break;
          case "select_multiple":
            this.$set(this.filterEntity, filterField.key, { $in: filterField.defaultSelectOptions || [] });
            if (filterField.options !== undefined) {
            //console.log('logD filter options', filterField.fieldDefinition.options, filterField.options);
            filterField.fieldDefinition.options.map((opt: any) => {
              //console.log('option', opt);
              //console.log(opt.value, 'tem', filterField.options!.indexOf(opt.value) >= 0);
            })
            //filterField.fieldDefinition.options 
            const opts:any = [];
            filterField.fieldDefinition.options.map((opt : any) => {                
                if (filterField.options!.indexOf(opt.value) >= 0) {
                  opts.push(opt);
                }
            });
            filterField.fieldDefinition.options = opts;
            //console.log('logD filtered options', opts, filterField.fieldDefinition.options);
          }
            break;
          case "select_single_relation":
            this.$set(this.filterEntity, `${filterField.key}`, undefined);
            break;
          case "hidden":
            //console.log('logD filter hidden', filterField);
            this.$set(this.filterEntity, `${filterField.key}`, filterField.value);
            break;
          default:
            this.$set(this.filterEntity, filterField.fieldDefinition.relationType === "many-to-one" ? `${filterField.key}.name` : filterField.key, { $cont: "" });
        }
      });
      this.filterFields = filterFields;
      this.filterResults(); // load entity table after filter has finished loading
    } catch (error) {
      //console.error(error);
    }
    loading.close();
  }

  getDateString(date: Date) {
    const mes = date.getMonth() + 1;
    const dia = date.getDate();
    const sMes = mes >= 10 ? mes.toString() : `0${mes}`;
    const sDia = dia >= 10 ? dia.toString() : `0${dia}`;
    return `${date.getFullYear()}-${sMes}-${sDia}`;
  }

  async getFilterFields() {
    if (!this.entityDescription || !this.filterDefinition) {
      return [];
    }
    return Promise.all(this.filterDefinition.map(async (filterDefinitionField) => {
      const fieldDefinition = this.entityDescription.find((field: any) => field.key === filterDefinitionField.fieldKey) || {};

      const filterField = {
        ...filterDefinitionField,
        key: filterDefinitionField.fieldKey,
        fieldDefinition,
      };

      // select filter kind based on field kind
      if (!filterField.kind) {
        switch (fieldDefinition.kind) {
          case "date":
          case "datetime":
            filterField.kind = "date_interval";
            break;
          case "select":
            filterField.kind = "select_multiple";
            break;
          case "relation":
            filterField.kind = "select_single_relation";
            await this.loadManyToOneOptions(filterField);
            break;
          case "hidden":
            filterField.kind = "hidden";
            break;
          default:
            filterField.kind = "text";
        }
      }

      return filterField;
    }));
  }

  async loadManyToOneOptions(filterField: any) {
    if (filterField.model) {
      const searchOptions: any = {};
      const manyToOneModelDescription = await filterField.model.getDescription();
            if (manyToOneModelDescription.find((field: any) => field.key === 'name')) {
                searchOptions.sort = [{ field: 'name', order: 'ASC'}];
            }
      const options = await filterField.model.search(searchOptions, null, filterField.key);
      for (const option of options) {
        option.label = filterField.model.labelTransformer ? await filterField.model.labelTransformer(option) : option.name;
      }
      this.selectFieldsOptions[filterField.key] = options;
    }
  }


  filterResults() {
    //console.log(this.filterEntity);
    this.filterFields.forEach(filterField => {
      if (filterField.kind === 'select_single_relation' && this.filterEntity[filterField.key] === null) {
        this.filterEntity[filterField.key] = undefined; // turn null into undefined if no value is selected to disable filter field
      }
    })
    this.$emit('update');
  }
}
