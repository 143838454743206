











/* eslint-disable no-invalid-this */
import {
  Component, Vue, Watch, Prop,
} from "vue-property-decorator";
import EntityTable from "./EntityTable.vue";
import EntityForm from "./EntityForm.vue";
// eslint-disable-next-line no-unused-vars
import EntityFilter, { FilterDefinition } from "./EntityFilter.vue";

//não existia EntityFilter, foi implementado para deixar igual ao admin

@Component({
  components: { EntityTable, EntityForm, EntityFilter },
})
export default class EntityCrud extends Vue {
  @Prop() model: any;

  @Prop()
  title!: string;

  @Prop()
  tableColumns!: any[];  

  @Prop() filter: any;

  id: any = "";

  @Prop()
  formColumns!: any[];

  @Prop() defaultEntity: any;

  @Prop({ default: false })
  readOnly!: boolean;

  @Prop({ default: () => [] })
  actions!: {
    kind: 'TABLE'|'FORM'|'ALL';
    name: string;
    icon: string;
    color: string;
    action: any;
  }[];

  //deixar igual ao admin
  @Prop() filterDefinition?: FilterDefinition;
  filterEntity: any = {};
  @Prop() exportColumns: any[];
  @Prop({ default: true }) enableExport: boolean;

  //Para mudar os labels da grid
  @Prop() tableHeaders: any[];
  //Para mudar os labels da planilha de exportação
  @Prop() exportHeaders: any[];
  
  async mounted() {    
    this.id = this.$route.params.id;
  }

  @Watch("$route")
  updateRoute(route: any) {
    this.id = route.params.id || "";
  }

  get tableActions() {
    return this.actions.filter((action) => action.kind === 'TABLE' || action.kind === 'ALL');
  }

  get formActions() {
    return this.actions.filter((action) => action.kind === 'FORM' || action.kind === 'ALL');
  }

  //deixar igual ao admin
  get combinedFilter() {
    //console.log('combined filter', { ...this.filter, ...this.filterEntity });
    return { ...this.filter, ...this.filterEntity };
  }

  refresh() {
    //(this.$refs.crudTable as any)?.refresh();
    //deixar igual ao admin
    if (this.$refs.crudTable) {
      (this.$refs.crudTable as any).refresh();
    }
  }
}
