




























































//alterações no html para funcionar com o vuesax4, o do admin é uma versão anterior e mudaram o filter, paginação, pesquisa...

import {
  Component, Vue, Watch, Prop,
} from "vue-property-decorator";
import { format, parseISO } from "date-fns";
import debounce from "debounce";

//imports para exportação de planilha
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import XLSX from 'xlsx';
import AusterButton from "@/components/AusterButton.vue";

@Component({ components: { AusterButton }})
export default class EntityTable extends Vue {
  @Prop({default: 1}) page: number;  
  @Prop({default: ''}) search: string;

  @Prop() title: string|undefined;

  @Prop() model: any;

  @Prop() columns: string[]|undefined;
  //Para mudar os labels da grid
  @Prop() headers: string[];  

  @Prop({ default: 20 }) pageLimit: number|undefined;  
        

  @Prop({ default: () => {} }) filter: any;

  @Prop({ default: true }) enableCreation: boolean|undefined;

  @Prop({ default: false }) readOnly: boolean|undefined;

  //deixar igual ao admin
  @Prop({ default: false }) disableAutoSearch: boolean;

  @Prop({ default: () => [] })
  actions!: {
    name: string;
    icon: string;
    color: string;
    action: any;
  }[];

  entityDescription: any = [];

  // current search state
  currentPage = 1;

  currentSearchTerm = "";

  currentSortKey = "";

  currentSortDirection: string | null = null;

  currentTotal = 0;

  results: any = [];

  selectedRow: any = {};
  //flag para exibição de espera
  loading = false;
  //Para exportação de registros, igual ao admin
  selectedRows: any = []; // if multiple selection is disable, this becomes an object
  @Prop() exportColumns: string[];
  //para mudar os labels da planilha de exportação
  @Prop() exportHeaders: string[];

  @Prop({ default: true }) enableExport: boolean;

  created() {
    this.handleSearch = debounce(this.handleSearch, 400);
  }

  async mounted() {
    this.entityDescription = await this.model.getDescription();
    //console.log('logD mounted', this.tableFields);
    //if (!this.disableAutoSearch) {
      this.handleSort("", null); // initialize default sort and perform initial search
    //}
  }

  get tableFields() {
    if (!this.entityDescription) {
      return [];
    }
    //console.log('logD entity description', this.entityDescription);

    //return this.columns.map((fieldKey: string) => this.entityDescription.find((field: any) => field.key === fieldKey) || {});
    const result = this.entityDescription.filter((fieldDescription: any) => this.columns.includes(fieldDescription.key));

    //Respeitar a ordem do array this.columns para exibir as colunas da grid, estava sendo ordenado pela definição dos campos no entity da api
    const fields = [];
    for (let i = 0; i < this.columns.length; i++) {
      const exists = result.find((r: any) => this.columns[i].includes(r.key));
      if (exists !== null && exists !== undefined) {
        if (this.headers && this.headers.length === this.columns.length) {
          exists.name = this.headers[i];
        }
        fields.push(exists);
      }
    }

    return fields;
  }

  get exportFields(): any[] {
    if (!this.entityDescription) {
      return [];
    }

    //verificar se as colunas e headers para exportação foram definidas
    const columns = this.exportColumns && this.exportColumns.length > 0 ? this.exportColumns: this.columns;
    const headers = this.exportHeaders && this.exportHeaders.length > 0 ? this.exportHeaders: this.headers;

    //deixar igual ao admin
    const result = this.entityDescription.filter((fieldDescription: any) => columns.includes(fieldDescription.key));    
    //Respeitar a ordem do array this.exportColumns ou this.columns (caso  primeiro não exista) para montar as colunas da planilha, estava sendo ordenado pela definição dos campos no entity da api
    const fields = [];
    for (let i = 0; i < columns.length; i++) {
      const exists = result.find((r: any) => columns[i] === r.key);
      if (exists !== null && exists !== undefined) {
        if (headers && headers.length === columns.length) {
          exists.name = headers[i];
        }
        fields.push(exists);
      }
    }

    //console.log('ordered columns', fields);
    return fields;
  }

  //deixar igual ao admin
  getFieldSort(field: any, fieldKey: string) {
    if (field.kind === "relation") {
        return `${fieldKey}.name`;
    } else {
      return fieldKey;
    }
  }

  getFieldContent(field: any, fieldKey: string, fieldData: any) {
    // console.log('logD getfieldContent', fieldData)
    // console.log('logD getfieldKey', fieldKey)    
    // console.log('logD getfieldKind', field.kind)    
    switch (field.kind) {
      case "select":
        //return field.options.find((option: any) => option.value === fieldData[fieldKey]).name;
        //deixar igual ao admin
        return fieldData[fieldKey] ? field.options.find((option: any) => option.value === fieldData[fieldKey]).name : "-";
      case "date":
        return fieldData[fieldKey] ? format(parseISO(fieldData[fieldKey]), "dd/MM/yyyy HH:mm") : "-";
      case "relation":
        return fieldData[`${fieldKey}.name`];
    }
    return fieldData[fieldKey];
  }

  //deixar igual ao admin
  async handleSearch(term: string) {
    if (!this.currentSortKey) {
      return;
    }

    // console.log("logD entity description", JSON.stringify(this.entityDescription));
    // console.log("logD columns", JSON.stringify(this.entityDescription))    
    // console.log('logD handlesearch', JSON.stringify(this.tableFields));
    
    this.currentSearchTerm = term;
    //a diferença do admin, não vai mandar parametros de paginação
    const queryParameters = {
      // page: this.currentPage,
      // limit: this.pageLimit,
      sort: [{
        field: this.currentSortKey,
        order: this.currentSortDirection,
      }],
      search: {
        ...this.filter,
        $or: this.tableFields.filter((field) => field.kind !== 'relation' && field.kind !== 'action').map((field) => ({
          [field.key]: {
            $cont: this.currentSearchTerm,
          },
        })),
      },
      //join: this.entityDescription.filter((fieldDescription: any) => fieldDescription.kind === 'relation' && this.columns.includes(fieldDescription.key)).map((fieldDescription: any) => ({ field: fieldDescription.key })),
      join: this.entityDescription.filter((fieldDescription: any) => fieldDescription.kind === 'relation' && this.tableFields.find(field => fieldDescription.key === field.key || fieldDescription.key === field.join)).map((fieldDescription: any) => ({ field: fieldDescription.key })),
    };
    this.loading = true;
    const vLoading = this.$vs.loading();
    try {
      let response = await this.model.search(queryParameters);
      //console.log('logD response', response);
      if (!response.length) {
        response = response.data;
      }

      //this.currentTotal = response.total;
      //a diferença do admin o response é o resultado total de registros
      //o vuesax vai fazer a paginação / filtro dos registros.
      this.currentTotal = response.length;
      this.results = response;
      // padding workaround due to broken server side table implementation
      //const leftPaddedResults = Array(this.pageLimit * (this.currentPage - 1)).map(() => ({})).concat(response.data); // left pad results with blank rows
      //this.results = leftPaddedResults.concat(Array(this.currentTotal - leftPaddedResults.length).map(() => ({}))); // right pad results until total length is reached
      
      //this.results = response.data.map((entity: any) => this.selectedRows.find((row: any) => row.id === entity.id) || entity);

      //console.log('logD results', this.currentTotal, this.results);
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
    vLoading.close();
  }

  //permitir exportação igual o admin
  async exportSearch() {
			const queryParameters = {
        sort: [{
          field: this.currentSortKey,
          order: this.currentSortDirection,
        }],
        search: {
          ...this.filter,
          $or: this.exportFields.filter(field => field.kind !== 'relation' && field.kind !== 'action').map(field => ({
            [field.key]: {
              $cont: this.currentSearchTerm,
            },
          })),
        },
        join: this.entityDescription.filter((fieldDescription: any) => fieldDescription.kind === 'relation' && this.exportFields.find(field => fieldDescription.key === field.key || fieldDescription.key === field.join)).map((fieldDescription: any) => ({ field: fieldDescription.key })),
      };

    //this.$vs.loading();
    const vLoading = this.$vs.loading();
    try {
      let exportData = await this.model.search(queryParameters);
      //console.log('logD exportData', exportData);
      if (!exportData.length) {
        exportData = exportData.data;
      }
      if (!exportData.length) {
        vLoading.close();
				return;
			}
			const fieldsToExport = this.exportFields;
			// replace header names and translate select options
      const xlsData = exportData.map((row: any) => fieldsToExport.reduce((processedRow, field) => ({
				...processedRow,
				[field.name]: this.getFieldContent(field, field.key, row),
			}), {}));
			const exportWS = XLSX.utils.json_to_sheet(xlsData, { header: fieldsToExport.map(field => field.name) });
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, exportWS, 'Relatório');
			XLSX.writeFile(wb, 'relatorio.xlsx');

    } catch (error) {
      console.error(error);
    }
    vLoading.close();
  }

  async handleChangePage(page: number) {
    this.currentPage = page;
    await this.handleSearch(this.currentSearchTerm);
  }

  async handleSort(key: string, direction: string | null) {
    if (direction) {
      this.currentSortDirection = direction.toUpperCase();
      this.currentSortKey = key;
    } else {
      this.currentSortDirection = "ASC";
      //this.currentSortKey = (this.tableFields.find((field) => field.defaultSort) || this.tableFields[0] || {}).key;
      //deixar igual o admin
      this.currentSortKey = ((this.tableFields.find(field => field.defaultSort) || this.tableFields[0]) || {}).key;
    }
    //console.log('logD handle sort', this.currentSortKey);
    this.handleSearch(this.currentSearchTerm);
  }

  @Watch("selectedRow")
  rowChanged(newRow: any, oldRow: any) {
    if (newRow && newRow.id !== oldRow.id) {
      this.$router.push(`${this.$router.currentRoute.path}/${newRow.id}`);
    }
  }

  create() {
    this.$router.push(`${this.$router.currentRoute.path}/novo`);
  }

  runAction(event: any, action: any, entityData: any) {
    event.stopPropagation();
    action(entityData);
  }

  //deixar igual o admin
  async refresh() {
    //await this.handleSearch(this.currentSearchTerm);
    if (!this.currentSortKey) { // initialize sorting parameters if necessary
      await this.handleSort("", null);
    } else { // otherwise perform an ordinary search
      await this.handleSearch(this.currentSearchTerm);
    }
  }
}
